.challenge-icon.valorant_sage {
  transform: scale(1.5);
  margin-top: -6px;
}

.challenge-icon.valorant_raze {
  transform: scale(1.7);
  margin-top: -6px;
}

.challenge-icon.lol_ace {
  transform: scale(1.5);
  margin-top: -6px;
}