@import 'animations.scss';

.country-select label {
  /* color: #fff; */
}

html, body {
  padding: 0;
  margin: 0;
  background: transparent;
}

input:-webkit-autofill::first-line,
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border-radius: 0 !important;
  color: #fff;
  -webkit-box-shadow: 0 0 0 1000px #1B1D21 inset !important;
  -webkit-text-size-adjust: none;
  font-family: Muli, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol' !important;
  font-size: 16px;
}


.primary-color {
  color: #00caef;
}

.secondary-color {
  color: #e92e73;
}

h5 {
  margin: 0 0 5px 0;
}

.text-left {
  text-align: left;
}

.gr-trophy-icon path,
.base-drawer-list svg path {
  stroke: #fff !important;
}

.text-bold {
  font-weight: bold;
}

.text-center {
  text-align: center;
}

.text-capitalize {
  text-transform: capitalize;
}

.full-width {
  width: 100%;
}

.base-drawer-list svg path {
  stroke: #fff !important;
}

.streamer-links {
  width: 210px;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}


.amount-left-for-streamer {
  font-weight: bold;
  font-size: 23px;
}

.amount-after-paypal-fee {
  opacity: 0.4;
}


.recharts-custom-tooltip {
  z-index: 99999;
  background: #1B1D21;
  padding: 10px;
  outline: 1px solid #999;
}

.snapshot-img {
  margin-bottom: 10px;
  //width: 200px;
  display: block;
  outline: 1px solid purple;

}

.plain-logs textarea {
  height: 300px !important;
}

.parsed-logs textarea {
  min-height: 400px;
}

.multiline textarea {
  min-height: 100px !important;
  max-height: 200px !important;
}

.react-json-view {
  background: transparent !important;
}

.recharts-default-tooltip {
  background: #1B1D21 !important;

  .recharts-tooltip-item {
    color: #fff !important;
  }
}


.json-tooltip {
  z-index: 999999999999 !important;
}

.text-muted {
  color: #a0a6ae;
}

.confirm-withdraw-msg {
  font-size: 28px;
}

.metrics-table {
  .default-empty-msg,
  .MuiCircularProgress-root {
    display: none;
  }
}


.bytes-by-type-chart {
  .chart-labels {
    min-width: 250px !important;
  }

  .text-capitalize {
    text-transform: lowercase !important;
  }
}

.MuiToolbar-regular {
  min-height: 52px !important;
}

.gr-icon path {
  stroke: #fff !important;
}

.base-drawer-list {
  .MuiAccordionDetails-root {
    padding: 8px 0px 16px !important;
  }

  .MuiPaper-root {
    background: transparent !important;
  }

  .MuiAccordion-root:before {
    display: none;
  }

  .inner-accordion {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

}


.sketch-picker {
  top: 30px !important;
}


.message-label {
  max-width: 200px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-forgot-password {
  display: none;
}

.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
