
.base-card {
  overflow: visible !important;

  .MuiCardHeader-root {
    width: 100%;
    padding: 4px;
    font-size: 12px;
  }

  .MuiCardContent-root:last-child {
    padding-bottom: 14px;
  }

  &.transparent {
    background: transparent !important;
    box-shadow: none !important;
    border: 0 !important;
  }
}


.no-padding {
  > .base-card-content {
    padding: 0;
  }
}
