// Legendary
$color-legendary: #f8c100;
$color-legendary-light: rgba(248, 193, 0, 0.3);
// Epic
$color-epic: #d500f8;
$color-epic-light: rgba(213, 0, 248, 0.3);
//Rare
$color-rare: #0081F8;
$color-rare-light: rgba(0, 129, 248, 0.3);
//Common
$color-common: #baff84;
$color-common-light: rgba(186, 255, 132, 0.3);

.color-common {
  color: $color-common;
}

.color-rare {
  color: $color-rare;
}

.color-epic {
  color: $color-epic;
}

.color-legendary {
  color: $color-legendary;
}

.bg-common {
  background: $color-common;
}

.bg-rare {
  background: $color-rare;
}

.bg-epic {
  background: $color-epic;
}

.bg-legendary {
  background: $color-legendary;
}

.fill-common {
  fill: $color-common;
}

.fill-rare {
  fill: $color-rare;
}

.fill-epic {
  fill: $color-epic;
}

.fill-legendary {
  fill: $color-legendary;
}

.stroke-common {
  stroke: $color-common;
}

.stroke-rare {
  stroke: $color-rare;
}

.stroke-epic {
  stroke: $color-epic;
}

.stroke-legendary {
  stroke: $color-legendary;
}
