.color-picker__container {
  position: relative;
  margin-right: 20px;

  .color-box {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    margin-right: 15px;
  }

  .color-box-container {
    cursor: pointer;
    min-width: 160px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    background-color: #26282D;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 20px;
    margin-top: 4px;
    font-size: 16px;

  }

  .color-box-label {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;

  }

  .sketch-picker {
    background: #232427 !important;
    position: absolute;
    z-index: 99999;
    top: -265px;
    right: 0;
  }
}




