.MuiTableContainer-root {
  overflow: unset !important;
}

.MuiTableBody-root {
  border: unset;
  background-color: #23262B;
}

.MuiTableCell-head {
  background-color: #272C32 !important;
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 12px !important;
  border: 0 !important;
  height: 50px !important;
  line-height: inherit !important;
}

.MuiTableCell-body {
  color: #fff !important;
  border-bottom: 1px solid #ffffff17 !important;
  padding: 4px 16px !important;
}


.table-striped {
  .MuiTableRow-hover:nth-child(2n) {
    background-color: #2C313C
  }
}

.sails-table-pagination {
  .MuiSelect-icon {
    top: auto;
  }
}

.MuiTablePagination-root {
  background-color: #272C32 !important;

}

.pagination-navigation {
  width: 100%;
  margin-left: 10px;
}


// Maybe we do this css in a streamer-table / admin-table css to override, ( this is streamer-css ),
// Todo
.pagination-navigation {
  width: initial !important;

}

.MuiTablePagination-toolbar {
  padding: 0 16px !important;
  display: flex !important;
  justify-content: space-between !important;

  p:first-of-type {
    display: none;
  }

  .MuiTablePagination-caption {
    margin-right: auto;
  }
}

.MuiTablePagination-selectRoot {
  margin-left: 0 !important;
  margin-right: 5px !important;
  svg {
    color: #a0a6ae !important;
  }
  .MuiTablePagination-select {
    color: #a0a6ae !important;
    width: initial;
    text-align-last: auto !important;



  }

  .MuiSelect-icon {
    top: calc(50% - 17px)
  }

}

.MuiTablePagination-spacer {
  display: none !important;
}