.card-sound-uploader-container {
  position: relative;
  display: flex;

  .card-upload-cover {
    background: rgba(0, 0, 0, 0.9);
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .card-item__bottom {
    display: none;
  }

}


