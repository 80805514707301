.base-alert {
  .MuiSnackbarContent-message {
    width: calc(100% - 60px);
  }

  .base-alert-icon {
    display: flex;
    align-items: center;
  }


}