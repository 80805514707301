input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  border-radius: 4px;
  margin-right: 2px;
  opacity: 1;
  filter: invert(1);
}

.form-input-count-down {
  display: flex;
  justifyContent: space-between;

  > div > div {
    width: 90px;
  }

  > div:not(:last-child) {
    margin-right: 5px;
  }
}
