.input-message {
  height: 130px;

  textarea {
    height: 80px !important;
  }
}

.hidden {
  display: none !important;
}
