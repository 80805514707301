$anime-time: 8s;

$box-size: 46px;
$clip-distance: 0.05;
$clip-size: $box-size * (1 + $clip-distance * 2);
$path-width: 2px;

$main-color: #0eb752;


%full-fill {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.transformer-border-animation {
  display: none;
  @extend %full-fill;
  width: $box-size;
  height: $box-size;
  margin: auto;
  transform: rotate(-45deg);
  left: -2px;
  //background: $codepen-logo-path no-repeat 50% / 70% rgba(#000, 0.1);
  color: $main-color;
  //box-shadow: inset 0 0 0 1px rgba($main-color, 0.5);

  &::before,
  &::after {
    @extend %full-fill;
    content: "";
    z-index: -1;
    margin: -1 * $clip-distance * 100%;
    box-shadow: inset 0 0 0 $path-width;
    animation: clipMe $anime-time linear infinite;
  }

  &::before {
    animation-delay: $anime-time * -0.5;
  }

  // for debug
  &:hover {
    &::after,
    &::before {
      //background-color: rgba(#f00, 0.3);
    }
  }
}

@keyframes clipMe {
  0%,
  100% {
    clip: rect(0px, $clip-size, $path-width, 0px);
  }
  25% {
    clip: rect(0px, $path-width, $clip-size, 0px);
  }
  50% {
    clip: rect($clip-size - $path-width, $clip-size, $clip-size, 0px);
  }
  75% {
    clip: rect(0px, $clip-size, $clip-size, $clip-size - $path-width);
  }
}


.pulse-animation {
  //margin:100px;
  //display: block;
  //width: 22px;
  //height: 22px;
  //border-radius: 50%;
  //background: #cca92c;
  //cursor: pointer;

  box-shadow: 0 0 0 rgba(248, 59, 59, 0.4);
  animation: pulse 2s infinite;
}

.pulse-animation span {
  //color: rgba(248, 59, 59, 1);

}

.pulse-animation:hover {
  //animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(248, 59, 59, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(248, 59, 59, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(248, 59, 59, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(248, 59, 59, 0.4);
    box-shadow: 0 0 0 0 rgba(248, 59, 59, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(248, 59, 59, 0);
    box-shadow: 0 0 0 10px rgba(248, 59, 59, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(248, 59, 59, 0);
    box-shadow: 0 0 0 0 rgba(248, 59, 59, 0);
  }
}