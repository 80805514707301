.sound-select {
  button {
    display: none;
  }
}

.sound-select-item {
  button {
    font-size: 19px;
  }
}


.sound-select {


  legend,
  .select-label {
    display: none;
  }
}

.ca_and_contract_sound-label {
  margin-bottom: 10px;
}