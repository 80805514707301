.select {
  width: 100%;
  min-width: 250px;


}

.MuiFormControl-root {
  width: 100%;
}

.Mui-selected.gg-selected {
  //color: #e92e73;
  font-weight: 500;
}

.multi-select {
}

.single-select {
  position: relative;

  &.has-error {
    fieldset {
      border: 1px solid #f44336 !important;

    }

    label {
      color: #f44336 !important;
    }
  }

}

label.select-label {
  font-size: 17px;
  z-index: 50;
  position: absolute;
  left: 9px;
  background: #1B1D21;
  width: auto;
  padding: 0 8px;
  pointer-events: none;
  margin-bottom: 15px;
  top: -6px;
  text-transform: capitalize;


}

//.single-select-paper {
//  margin-top: 108px;
//}
