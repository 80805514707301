.label-online {
  background: green;
  color: #fff;
  padding: 5px 10px;
}

.label-offline {
  background: red;
  color: #fff;
  padding: 5px 10px;
}
