.country-select {

  label.Mui-focused:not(.Mui-error) {
    color: #FF2C90;
  }


  label:not(.Mui-error) {

    color: #fff;
  }

}
