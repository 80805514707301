@font-face {
  font-family: "BrandonText-Regular";
  src: url("fonts/brandon/HVD Fonts - BrandonText-Regular.otf") format("woff2");
}

@font-face {
  font-family: "BrandonText-Light";
  src: url("fonts/brandon/HVD Fonts - BrandonText-Light.otf") format("woff2");
}

@font-face {
  font-family: "BrandonText-Medium";
  src: url("fonts/brandon/HVD Fonts - BrandonText-Medium.otf") format("woff2");
}

@font-face {
  font-family: "BrandonText-Bold";
  src: url("fonts/brandon/HVD Fonts - BrandonText-Bold.otf") format("woff2");
}

@font-face {
  font-family: "BrandonText-Thin";
  src: url("fonts/brandon/HVD Fonts - BrandonText-Thin.otf") format("woff2");
}

@font-face {
  font-family: "BrandonText-Italic";
  src: url("fonts/brandon/HVD Fonts - BrandonText-RegularItalic.otf") format("woff2");
}

@font-face {
  font-family: "poster-gothic-round-light";
  src: url("fonts/Poster_Gothic_Round_ATF/PosterGothicRoundATF-Light.otf") format("woff2");
}

@font-face {
  font-family: "poster-gothic-round-atf";
  src: url("fonts/Poster_Gothic_Round_ATF/PosterGothicRoundATF.otf") format("woff2");
}

@font-face {
  font-family: "poster-gothic-round-bold";
  src: url("fonts/Poster_Gothic_Round_ATF/PosterGothicRoundATF-Bold.otf") format("woff2");
}

@font-face {
  font-family: "Ultraquick";
  src: url("fonts/ultraquick/Ultraquick.otf") format("woff2");
}

@font-face {
  font-family: "Ultraquick-Italic";
  src: url("fonts/ultraquick/Ultraquick Italic.otf") format("woff2");
}


@font-face {
  font-family: "Oxygen-Bold";
  src: url("fonts/oxygen/Oxygen-Bold.ttf") format("woff2");
}

@font-face {
  font-family: "Oxygen-Light";
  src: url("fonts/oxygen/Oxygen-Light.ttf") format("woff2");

}

@font-face {
  font-family: "Oxygen-Regular";
  src: url("fonts/oxygen/Oxygen-Regular.ttf") format("woff2");
}