.social-platform-picker {
  display: flex;
  align-items: center;
  .__icon {
    margin-right: 6px;
  }
}


.sound-select {
  width: 100%;
}

.tts-select {
  width: 100%;

  > div {
    display: flex;
    align-items: center;
  }
}


.tts-v {
  display: inline-block;
  width: 190px
}
