.chips-array {
  list-style: none;
  margin: 0;
  padding: 0;

  .chip {
    //display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    display: flex;

  }
}