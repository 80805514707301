.card-item {
  position: relative;
  width: 250px;
  cursor: pointer;
  margin-bottom: 20px;

  &.hide-bottom {
    .card-item__bottom {
      display: none;
    }
  }

  &.card-video {
    //width: 520px !important;

    .card-overlay {
      display: none;
    }

    video {
      position: relative;
      top: -80px;
      left: -180px;
      transform: scale(0.83);
      //background: red;
    }


    .card-image-container {
      border-radius: 8px;
      clip-path: polygon(91px 10px, 242px 10px, 242px 290px, 157px 376px, 10px 376px, 10px 94px);
    }
  }

  &.small {
    width: 200px;

    .card-bg {
      height: 295px;
    }

    .card-overlay {
      top: 223px;
      height: 65px;
    }

    .ca-by-ca {
      top: 196px;
    }

    .card-item__image {
      padding: 7px;
      height: 295px;

    }

    .card-item__bottom {
      margin-top: -9px;
    }

    .card-image-container {
      clip-path: polygon(79px 0, 100% 0, 100% 215px, 118px 100%, 0 100%, 0 78px)
    }

    svg {
      font-size: 20px;
    }
  }

  svg {
    font-size: 26px;
  }

  &.card-disabled {
    opacity: 0.2;
  }

  .card-image-container {
    // https://bennettfeely.com/clippy/
    //clip-path: polygon(34% 0, 100% 0, 100% 75%, 66% 100%, 0 100%, 0 25%);
    //clip-path: polygon(37% 0, 100% 0, 100% 76%, 63% 100%, 0 100%, 0 24%);
    clip-path: polygon(103px 0, 100% 0, 100% 280px, 147px 100%, 0 100%, 0 104px);
    position: relative;
    z-index: 1;
  }

  &:hover {
    .card-overlay {
      background: rgba(0, 0, 0, 0.6);

    }
  }

  .card-overlay {
    position: absolute;
    top: 280px;
    //left: 9px;
    //right: 9px;
    left: 2px;
    right: 16px;
    background: rgba(0, 0, 0, 0.3);
    padding: 8px 16px;
    height: 95px;
    display: flex;
    font-size: 20px;
    font-weight: bold;
    flex-direction: column;
    justify-content: space-between;

    .card-name {
      font-size: 18px;
      //letter-spacing: 1.6px;
      font-family: "poster-gothic-round-bold";
    }


  }

  &__image {
    padding: 10px;
    width: 100%;
    height: 385px;
    display: flex;
    border-radius: 8px;
    background-size: cover;
  }


  &__bottom {
    padding: 5px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: -7px;

    .features {
      display: flex;
      align-items: center;
    }

    .feature {
      display: flex;
      align-items: center;
      padding: 2px;
      margin-right: 10px;
    }

    .price {
      font-weight: bold;
      height: fit-content;
      width: fit-content;
    }
  }

}

.card-actions-container {
  display: flex;

}


.card-bg {
  //enable-background: new 0 0 225 347;
  position: absolute;
  height: 385px;
  width: 100% !important;
  z-index: 999;
}

.card-price {
  position: absolute;
  top: 43px;
  right: 8px;
  color: #1b1d21;
  font-family: "poster-gothic-round-bold";
  font-size: 25px;
  height: 40px;

  .bg-price {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 10;

  }

  span {
    position: relative;
    z-index: 15;
  }

  .card-price-content {
    position: relative;
    height: 100%;
    padding-right: 10px;
    display: flex;
    align-items: center;

  }


}

.bg-level {
  height: 18px;
  margin-top: 7px;
}

.ca-by-ca {
  position: absolute;
  top: 249px;
  left: 9px;

  img {
    height: 28px;
  }
}

.automatic-verification {
  font-size: 15px;
  margin: 10px 0;
  font-family: "poster-gothic-round-bold";
}

.overlay-actions {
  .card-type-icon {
    margin-right: 5px !important;
  }

  .action-card-type {
    margin-top: 5px;
  }

  .card-type-text {
    font-family: "poster-gothic-round-bold";
    font-size: 14px;
    color: #a0a6ae;
  }

  .action-card-type {
    svg {
      width: 25px !important;
      height: 25px !important;

      path {
        //fill: #a0a6ae;
      }
    }
  }
}

